export function getExternalReferrer() {
  const { referrer } = document;
  if (referrer.indexOf(window.location.hostname) !== -1 || referrer === '') {
    return null;
  }
  return referrer;
}

export function getUtmParameters() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utmParams: Record<string, string> = {};

  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    }
  });
  return utmParams;
}
