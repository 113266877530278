import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-close-icon/jb-close-icon.scss?inline
var jb_close_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

svg {
  cursor: pointer;
  display: inline-block;
  fill: rgb(var(--rgb-primary, 20, 30, 85));
  height: var(--icon-size, 29px);
  transform: scale(1);
  transition: transform 0.3s linear;
  width: var(--icon-size, 30px);
}
svg:hover {
  transform: scale3d(0.8, 0.8, 1);
  transition: transform 0.3s linear;
}`;

// src/components/icons/jb-close-icon/jb-close-icon.ts
var JbCloseIcon = class extends r2 {
  render() {
    return ke`
      <svg
        viewbox="0 0 30 29"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          stroke="none"
          stroke-width="1"
          fill-rule="evenodd"
        >
          <g transform="translate(-1825.000000, -45.000000)">
            <g transform="translate(1820.000000, 45.000000)">
              <path
                d="M19.2926829,14.5 L5.5,0.707317073 L6.20731707,-2.1151414e-12 L20,13.7926829 L33.7926829,-2.11031193e-12 L34.5,0.707317073 L20.7073171,14.5 L34.5,28.2926829 L33.7926829,29 L20,15.2073171 L6.20731707,29 L5.5,28.2926829 L19.2926829,14.5 Z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    `;
  }
};
JbCloseIcon.styles = r(jb_close_icon_default);
JbCloseIcon = __decorateClass([
  customElement("jb-close-icon")
], JbCloseIcon);

export {
  JbCloseIcon
};
