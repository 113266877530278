import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-download-icon/jb-download-icon.scss?inline
var jb_download_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  height: var(--icon-size, 18px);
  width: var(--icon-size, 18px);
}

svg {
  fill: rgb(var(--rgb-primary, 20, 30, 85));
  transition: var(--icon-transition, none);
}`;

// src/components/icons/jb-download-icon/jb-download-icon.ts
var JbDownloadIcon = class extends r2 {
  render() {
    return ke`
      <svg
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.30841 9.65421C2.30841 9.29288 2.01553 9 1.65421 9C1.29288 9 1 9.29288 1 9.65421V14.6166C1.00003 15.8894 2.03556 16.9249 3.30838 16.9249H14.6798C15.9526 16.9249 16.9882 15.8894 16.9882 14.6166V9.65421C16.9882 9.29288 16.6953 9 16.334 9C15.9727 9 15.6798 9.29288 15.6798 9.65421V14.6166C15.6798 15.1679 15.2312 15.6165 14.6799 15.6165H3.30835C2.75698 15.6165 2.30841 15.1679 2.30841 14.6166V9.65421Z"
        />
        <path
          d="M8.65156 11.6961C8.88436 11.9289 9.26182 11.9289 9.49464 11.6961L9.53035 11.6604L12.6922 8.49856C12.925 8.26574 12.925 7.88828 12.6922 7.65547C12.4594 7.42267 12.0819 7.42265 11.8491 7.65547L9.71976 9.7848L9.71976 1.91451C9.71975 1.55737 9.43024 1.26786 9.07311 1.26786C8.89454 1.26784 8.73286 1.34024 8.61584 1.45726C8.4988 1.57431 8.42644 1.73598 8.42644 1.91453L8.42646 9.78482L6.29712 7.65547C6.06431 7.42267 5.68686 7.42265 5.45403 7.65547C5.22121 7.8883 5.22123 8.26576 5.45403 8.49856L8.61586 11.6604L8.65156 11.6961Z"
        />
      </svg>
    `;
  }
};
JbDownloadIcon.styles = r(jb_download_icon_default);
JbDownloadIcon = __decorateClass([
  customElement("jb-download-icon")
], JbDownloadIcon);

export {
  JbDownloadIcon
};
