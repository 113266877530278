import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-external-icon/jb-external-icon.scss?inline
var jb_external_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  height: var(--icon-size, 18px);
  width: var(--icon-size, 18px);
}

svg {
  fill: rgb(var(--rgb-primary, 20, 30, 85));
}`;

// src/components/icons/jb-external-icon/jb-external-icon.ts
var JbExternalIcon = class extends r2 {
  render() {
    return ke`
      <svg
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0601 9.07511C13.6988 9.07511 13.4059 9.36799 13.4059 9.72932V14.6917C13.4059 15.243 12.9573 15.6916 12.4059 15.6916H3.30832C2.75698 15.6916 2.30841 15.243 2.30841 14.6917V5.59404C2.30841 5.0427 2.75698 4.59413 3.30832 4.59413H8.27066C8.63196 4.59413 8.92487 4.30122 8.92487 3.93992C8.92487 3.57862 8.63196 3.28571 8.27066 3.28571H3.30832C2.03553 3.28571 1 4.32124 1 5.59404V14.6917C1 15.9645 2.03553 17 3.30832 17H12.4059C13.6787 17 14.7143 15.9645 14.7143 14.6917V9.72932C14.7143 9.36799 14.4214 9.07511 14.0601 9.07511Z"
        />
        <path
          d="M16.4039 1H11.8819C11.5526 1 11.2857 1.26692 11.2857 1.59615C11.2857 1.92539 11.5526 2.1923 11.8819 2.1923H15.8077V6.11813C15.8077 6.44737 16.0746 6.71429 16.4039 6.71429C16.7331 6.71429 17 6.44737 17 6.11813V1.59615C17 1.26692 16.7331 1 16.4039 1Z"
        />
        <path
          d="M16.8106 1.18938C16.5581 0.936874 16.1486 0.936874 15.8961 1.18938L6.90368 10.1818C6.65115 10.4343 6.65115 10.8438 6.90368 11.0963C7.02993 11.2226 7.19545 11.2857 7.36094 11.2857C7.52647 11.2857 7.69196 11.2226 7.81821 11.0963L16.8106 2.10388C17.0631 1.85134 17.0631 1.44191 16.8106 1.18938Z"
        />
      </svg>
    `;
  }
};
JbExternalIcon.styles = r(jb_external_icon_default);
JbExternalIcon = __decorateClass([
  customElement("jb-external-icon")
], JbExternalIcon);

export {
  JbExternalIcon
};
