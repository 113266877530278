import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-service-navigation/jb-service-navigation.scss?inline
var jb_service_navigation_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

.service-navigation {
  align-self: center;
  display: var(--display, flex);
  font-size: 0;
  gap: var(--spacing-m);
  justify-content: center;
  opacity: 1;
  text-decoration: none;
  transition: opacity 0.35s ease-in-out 0.1s;
}
.service-navigation__bottom-links {
  margin-bottom: 90px;
  margin-top: 40px;
  text-align: center;
}
@media (orientation: landscape) {
  .service-navigation__bottom-links--second-level {
    margin-bottom: 70px;
  }
}
.service-navigation--hidden {
  opacity: 0;
}
.service-navigation--hide-all-except-first ::slotted(jb-service-navigation-item:nth-child(n+2)) {
  display: none;
}
.service-navigation--hide-first ::slotted(jb-service-navigation-item:first-child) {
  display: none;
}
.service-navigation--hide-all ::slotted(jb-service-navigation-item) {
  display: none;
}`;

// src/components/jb-service-navigation/jb-service-navigation.ts
var JbServiceNavigation = class extends r2 {
  constructor() {
    super(...arguments);
    this._usesTabToNavigateInsteadOfArrowKeys = true;
  }
  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    this.addEventListener("focusout", this._handleFocusOut);
  }
  _activateVisibleItems() {
    this._getVisibleItems().forEach((item) => {
      item.shadowRoot.querySelector("li")?.setAttribute("tabindex", "0");
    });
  }
  _focusNextItem(currentItem) {
    const visibleItems = this._getVisibleItems();
    const currentItemIndex = this._getCurrentItemIndex(currentItem);
    const nextItem = visibleItems[currentItemIndex + 1];
    if (nextItem) {
      nextItem.shadowRoot.querySelector("li")?.focus();
    }
  }
  _focusPreviousItem(currentItem) {
    const visibleItems = this._getVisibleItems();
    const currentItemIndex = this._getCurrentItemIndex(currentItem);
    const previousItem = visibleItems[currentItemIndex - 1];
    if (previousItem) {
      previousItem.shadowRoot.querySelector("li")?.focus();
    }
  }
  _getCurrentItemIndex(item) {
    let index = 0;
    this._getVisibleItems().forEach((visibleItem, i2) => {
      if (visibleItem === item) {
        index = i2;
      }
    });
    return index;
  }
  _getVisibleItems() {
    return Array.from(this.children).filter((item) => {
      const listElement = item.shadowRoot.querySelector("li");
      return listElement.clientHeight !== 0;
    });
  }
  _handleFocus() {
    this._activateVisibleItems();
  }
  _handleFocusOut(evt) {
    const jbNavigation = document.querySelector("jb-navigation");
    const jbMenu = document.querySelector("jb-menu");
    const currentItemIndex = this._getCurrentItemIndex(evt.target);
    if (jbMenu?.shadowRoot.querySelector("nav.mobile-menu") && currentItemIndex === this._getVisibleItems().length - 1 && this._usesTabToNavigateInsteadOfArrowKeys) {
      jbNavigation?.focus();
    }
  }
  _handleKeyboardEvent(evt) {
    const firstVisibleItem = this._getVisibleItems()[0];
    const jbMenu = document.querySelector("jb-menu");
    let keyCode = evt.code;
    if (evt.key === "Tab" && evt.shiftKey) {
      keyCode = "Shift+Tab";
    }
    switch (keyCode) {
      case "ArrowLeft":
        this._usesTabToNavigateInsteadOfArrowKeys = false;
        this._focusPreviousItem(evt.target);
        break;
      case "ArrowRight":
        this._focusNextItem(evt.target);
        this._usesTabToNavigateInsteadOfArrowKeys = false;
        break;
      case "Enter":
      case "Space":
        evt.preventDefault();
        evt.target.shadowRoot.querySelector("a")?.click();
        break;
      case "Shift+Tab":
        if (firstVisibleItem === evt.target && document.querySelector("jb-overlay")) {
          evt.preventDefault();
          if (jbMenu.shadowRoot.querySelector("nav.mobile-menu")) {
            jbMenu.shadowRoot.querySelector("jb-menu-item")?.focus();
          } else {
            jbMenu?.focus();
          }
        }
        break;
      default:
        break;
    }
  }
  render() {
    return ke`
      <ul
        class=${Rt({
      "service-navigation": true,
      "service-navigation--hide-first": this.hideItems === "first",
      "service-navigation--hide-all-except-first": this.hideItems === "all-except-first",
      "service-navigation--hide-all": this.hideItems === "all"
    })}
        @keydown=${this._handleKeyboardEvent}
        @focus=${this._handleFocus}
      >
        <slot></slot>
      </ul>
    `;
  }
};
JbServiceNavigation.styles = r(jb_service_navigation_default);
JbServiceNavigation.shadowRootOptions = { ...r2.shadowRootOptions, delegatesFocus: true };
__decorateClass([
  n()
], JbServiceNavigation.prototype, "hideItems", 2);
__decorateClass([
  r3()
], JbServiceNavigation.prototype, "_usesTabToNavigateInsteadOfArrowKeys", 2);
JbServiceNavigation = __decorateClass([
  customElement("jb-service-navigation")
], JbServiceNavigation);

export {
  JbServiceNavigation
};
