import {
  jb_select_default
} from "./chunk.ZVAXPIEQ.js";
import {
  se
} from "./chunk.YZ2RB7ZJ.js";
import {
  FormElement
} from "./chunk.UTVDNTSP.js";
import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  applyStyles,
  querySelectorAllShadow
} from "./chunk.C3CSSY4S.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n,
  r as r3
} from "./chunk.BBN2ZB42.js";
import {
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-dropdown/jb-dropdown.ts
var JbDropdown = class extends Initiable(FormElement(r2)) {
  constructor() {
    super(...arguments);
    this.inverted = false;
    this._dropdownIndex = 0;
    this._dropdownOpen = false;
    this._isFocused = false;
  }
  connectedCallback() {
    super.connectedCallback();
    applyStyles(this, jb_select_default);
  }
  closeDropdown() {
    this._dropdownOpen = false;
  }
  createRenderRoot() {
    return this;
  }
  firstUpdated() {
    this._dropdownIndex = this.options.map((d) => d.value).indexOf(this.value);
  }
  openDropdown() {
    if (this.disabled) return;
    const dropdowns = querySelectorAllShadow("jb-dropdown");
    dropdowns.forEach((dropdown) => {
      dropdown.closeDropdown();
    });
    this._dropdownOpen = true;
  }
  setValue(value) {
    this._handleOptionClick({ label: this._getLabel(value), value });
    return value;
  }
  _getLabel(value) {
    if (!value) return "";
    return this.options.find((i) => i.value === value)?.label;
  }
  _handleOptionClick(option) {
    this.value = option.value;
    this.closeDropdown();
    this.querySelectorAll(".active").forEach((item) => {
      item.classList.remove("active");
    });
    this.dispatchEvent(
      new CustomEvent("jb-select:changed", {
        detail: {
          id: this.id,
          newValue: this.value,
          label: option.label
        },
        bubbles: true,
        composed: true
      })
    );
  }
  _keyboardListener(evt) {
    if (this.disabled) return;
    evt.preventDefault();
    const optionElements = this.querySelectorAll(".option");
    switch (evt.code) {
      case "Space":
      case "Enter":
        if (this._dropdownOpen) {
          this._handleOptionClick(this.options[this._dropdownIndex]);
        } else {
          this._toggleDropdown();
        }
        break;
      case "ArrowDown":
        if (this._dropdownOpen) {
          if (this._dropdownIndex < this.options.length - 1) {
            this._dropdownIndex += 1;
            const dropdownItem = optionElements[this._dropdownIndex];
            dropdownItem.focus();
            dropdownItem.scrollIntoView();
          }
        } else {
          this.openDropdown();
        }
        break;
      case "ArrowUp":
        if (this._dropdownOpen) {
          if (this._dropdownIndex > 0) {
            this._dropdownIndex -= 1;
            const dropdownItem = optionElements[this._dropdownIndex];
            dropdownItem.focus();
            dropdownItem.scrollIntoView();
          }
        }
        break;
      case "Escape":
        if (this._dropdownOpen) {
          this._dropdownOpen = false;
        }
        break;
      default:
    }
  }
  _toggleDropdown() {
    if (this.disabled) return;
    const dropdowns = querySelectorAllShadow("jb-dropdown");
    dropdowns.forEach((dropdown) => {
      if (dropdown.id === this.id) return;
      dropdown.closeDropdown();
    });
    if (this._dropdownOpen) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }
  render() {
    return ke`
      <div
        class="${Rt({
      error: !!this.errorText || this.showError,
      disabled: !!this.disabled,
      showlabel: !!this.showLabel,
      inverted: !!this.inverted,
      isfocused: !!this._isFocused,
      [this.type]: !!this.type
    })} dropdown"
      >
        ${this.label ? ke`<label>${this.label}${this.requiredText ? "*" : ""}</label>` : D}
        ${ke`
          <div
            @keydown=${{
      handleEvent: (evt) => {
        this._keyboardListener(evt);
      },
      capture: true
    }}
            class="ts-wrapper single full has-items ${Rt({
      "dropdown-active": this._dropdownOpen
    })}"
          >
            <div
              tabindex="0"
              class="ts-control"
              @click=${this._toggleDropdown}
            >
              ${this.type === "languageswitch" ? ke`<jb-globe-icon></jb-globe-icon><span class="shortitem">${this.value}</span>` : D}
              <div class="item">${this._getLabel(this.value || "0")}</div>
            </div>
            <div
              class="ts-dropdown single"
              style=${se({
      display: this._dropdownOpen ? "block" : "none"
    })}
            >
              <div class="ts-dropdown-content">
                ${this.options.map(
      (option, index) => ke`<div
                      @click=${{
        handleEvent: () => {
          this._handleOptionClick(option);
        }
      }}
                      data-selectable
                      class="option ${Rt({
        selected: this.value === option.value,
        active: index === this._dropdownIndex
      })}"
                      data-value="${option.value}"
                    >
                      <span>${option.label}</span>
                    </div>`
    )}
              </div>
            </div>
          </div>
        `}
        ${this.showError ? ke`<p>${this.requiredText}</p>` : D}
        ${this.errorText ? ke`<p>${this.errorText}</p>` : D}
        <div
          style="${se({
      display: this._dropdownOpen ? "block" : "none"
    })}
          position: fixed;
          inset: 0;"
          @click=${this.closeDropdown}
        ></div>
      </div>
    `;
  }
};
JbDropdown.styles = r(jb_select_default);
__decorateClass([
  n()
], JbDropdown.prototype, "autocomplete", 2);
__decorateClass([
  e("input")
], JbDropdown.prototype, "input", 2);
__decorateClass([
  n({ type: Boolean })
], JbDropdown.prototype, "inverted", 2);
__decorateClass([
  n({ type: Array })
], JbDropdown.prototype, "options", 2);
__decorateClass([
  n()
], JbDropdown.prototype, "placeholder", 2);
__decorateClass([
  n({ type: Boolean })
], JbDropdown.prototype, "showLabel", 2);
__decorateClass([
  n()
], JbDropdown.prototype, "type", 2);
__decorateClass([
  r3()
], JbDropdown.prototype, "_dropdownIndex", 2);
__decorateClass([
  r3()
], JbDropdown.prototype, "_dropdownOpen", 2);
__decorateClass([
  r3()
], JbDropdown.prototype, "_isFocused", 2);
JbDropdown = __decorateClass([
  customElement("jb-dropdown")
], JbDropdown);

export {
  JbDropdown
};
