import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/icons/jb-internal-icon/jb-internal-icon.scss?inline
var jb_internal_icon_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
  height: var(--icon-size, 18px);
  width: var(--icon-size, 18px);
}

svg {
  fill: rgb(var(--rgb-primary, 20, 30, 85));
  transition: var(--icon-transition, none);
}`;

// src/components/icons/jb-internal-icon/jb-internal-icon.ts
var JbInternalIcon = class extends r2 {
  render() {
    return ke`
      <svg
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_0_1765)">
          <path
            d="M16.8122 8.55535C17.0892 8.83234 17.0892 9.2814 16.8122 9.55836L12.1584 14.2122C11.8814 14.4892 11.4323 14.4892 11.1554 14.2122C10.8784 13.9352 10.8784 13.4862 11.1554 13.2092L15.3077 9.05686L11.1554 4.90452C10.8784 4.62753 10.8784 4.17847 11.1554 3.90151C11.4323 3.62454 11.8814 3.62452 12.1584 3.90151L16.8122 8.55535Z"
          />
          <rect
            x="1"
            y="8.3"
            width="15"
            height="1.5"
          />
        </g>
        <clipPath id="clip0_0_1765">
          <rect
            width="16"
            height="12"
            fill="white"
            transform="translate(1 3)"
          />
        </clipPath>
      </svg>
    `;
  }
};
JbInternalIcon.styles = r(jb_internal_icon_default);
JbInternalIcon = __decorateClass([
  customElement("jb-internal-icon")
], JbInternalIcon);

export {
  JbInternalIcon
};
