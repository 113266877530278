import {
  Spaceable
} from "./chunk.DD6TLOBP.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-news-page-hero/jb-news-page-hero.scss?inline
var jb_news_page_hero_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
.intro-section ul .languages-label, h5 {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.intro-section jb-date, .intro, h1 {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

h1 {
  font-size: var(--theme-typo-h1-mobile-fontsize, 2.125rem);
  line-height: var(--theme-typo-h1-mobile-lineheight, 2.75rem);
}
@media (min-width: 1281px) {
  h1 {
    font-size: var(--theme-typo-h1-desktop-fontsize, 3.125rem);
    line-height: var(--theme-typo-h1-desktop-lineheight, 4.375rem);
  }
}

h5 {
  font-size: var(--theme-typo-p-highlight-mobile-fontsize, 1.063rem);
  line-height: var(--theme-typo-p-highlight-mobile-lineheight, 1.875rem);
}
@media (min-width: 1281px) {
  h5 {
    font-size: var(--theme-typo-p-highlight-desktop-fontsize, 1.188rem);
    line-height: var(--theme-typo-p-highlight-desktop-lineheight, 2.125rem);
  }
}

.intro {
  font-size: var(--theme-typo-p-big-mobile-fontsize, 1.188rem);
  line-height: var(--theme-typo-p-big-mobile-lineheight, 1.75rem);
}
@media (min-width: 1281px) {
  .intro {
    font-size: var(--theme-typo-p-big-desktop-fontsize, 1.375rem);
    line-height: var(--theme-typo-p-big-desktop-lineheight, 2.125rem);
  }
}

.intro-section jb-date {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, var(--theme-opacity-80, 0.8)));
  font-size: 1rem;
  line-height: 1.625rem;
}

.intro-section ul .languages-label {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 0.688rem;
  letter-spacing: 0.063rem;
  line-height: 1rem;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}
@media (min-width: 1281px) {
  :host {
    padding: 36px 0 20px;
  }
}

.title {
  --text-opacity: 1;
}

.intro-section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 24px;
}
@media (min-width: 960px) {
  .intro-section {
    padding: 0 0 37px;
  }
}
.intro-section jb-date {
  --rgb-primary: 20, 30, 85;
  --text-opacity: 0.4;
  text-transform: uppercase;
}
.intro-section ul {
  align-items: center;
  display: flex;
  list-style-type: none;
}
.intro-section ul .languages-label {
  color: rgba(20, 30, 85, var(--theme-opacity-40, 0.4));
  display: none;
  padding-right: 15px;
  text-transform: uppercase;
}
@media (min-width: 1281px) {
  .intro-section ul .languages-label {
    display: block;
  }
}
.intro-section ul jb-language-item {
  padding: 0 15px;
  padding: 0 0.9375rem;
  --text-opacity: 1;
  position: relative;
}
.intro-section ul jb-language-item:hover {
  --text-opacity: 0.4;
}
.intro-section ul jb-language-item::after {
  width: 3px;
  width: 0.1875rem;
  height: 3px;
  height: 0.1875rem;
  right: -2px;
  right: -0.125rem;
  background-color: #141e55;
  border-radius: 100%;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.intro-section ul jb-language-item:first-of-type {
  padding-left: 0;
}
.intro-section ul jb-language-item:last-of-type {
  padding-right: 0;
}
.intro-section ul jb-language-item:last-of-type::after {
  display: none;
}

jb-breadcrumbs {
  display: block;
  margin-bottom: 26px;
  text-align: center;
  line-height: 36px;
  line-height: 2.25rem;
}
@media (min-width: 600px) {
  jb-breadcrumbs {
    text-align: left;
  }
}
@media (min-width: 960px) {
  jb-breadcrumbs {
    margin-bottom: 40px;
  }
}

.toolbar {
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}
.toolbar jb-article-share-bar {
  margin-left: auto;
}
@media (min-width: 960px) {
  .toolbar {
    margin-bottom: 32px;
  }
}

h1 {
  margin-bottom: 20px;
}

.intro {
  margin-bottom: 32px;
}`;

// src/components/jb-news-page-hero/jb-news-page-hero.ts
var JbNewsPageHero = class extends Spaceable(r2) {
  _getContentSpacingProperties() {
    return { type: "no-vertical-space" };
  }
  render() {
    return ke`<article>
      ${this.breadcrumbs && ke`
          <jb-breadcrumbs>
            ${this.breadcrumbs.backlink && ke`<jb-breadcrumbs-back-link
              .link=${this.breadcrumbs.backlink.link}
              .text=${this.breadcrumbs.backlink.text}
              slot="backlink"
            ></jb-breadcrumbs-back-link>`}
            ${this.breadcrumbs.items?.map(
      (breadcrumb) => ke`<jb-breadcrumbs-item
                  .link=${breadcrumb.link}
                  .text=${breadcrumb.text}
                  slot="items"
                ></jb-breadcrumbs-item>`
    )}
          </jb-breadcrumbs>
        `}
      <header>
        <div class="intro-section">
          <jb-date date=${this.date}></jb-date>
          ${this.languages?.length && ke`<ul>
              <li class="languages-label">${this.languagesLabel}:</li>
              ${this.languages?.map(
      (language) => ke`<jb-language-item
                    .text=${language.text}
                    .link=${language.link}
                  ></jb-language-item>`
    )}
            </ul>`}
        </div>
      </header>
      <div class="title">
        ${this.subline && ke`<h5>${this.subline}</h5>`}
        <h1>${this.headline}</h1>
      </div>
      ${this.shareBarLabel && ke`<div class="toolbar">
          ${this.mailSubject && this.shareBarLabel && this.sharingUrl && ke`
            <jb-article-share-bar
              .articleTitle=${this.headline}
              .mailSubject=${this.mailSubject}
              .title=${this.shareBarLabel}
              .shareServices=${this.shareServices}
              .url=${this.sharingUrl}
            ></jb-article-share-bar>
          `}
        </div>`}
      <div class="intro">${this.intro}</div>
      </div>
      <slot></slot>
    </article>`;
  }
};
JbNewsPageHero.styles = r(jb_news_page_hero_default);
__decorateClass([
  n({ type: Object })
], JbNewsPageHero.prototype, "breadcrumbs", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "date", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "headline", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "intro", 2);
__decorateClass([
  n({ type: Array })
], JbNewsPageHero.prototype, "languages", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "languagesLabel", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "mailSubject", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "shareBarLabel", 2);
__decorateClass([
  n({ type: Array })
], JbNewsPageHero.prototype, "shareServices", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "sharingUrl", 2);
__decorateClass([
  n()
], JbNewsPageHero.prototype, "subline", 2);
JbNewsPageHero = __decorateClass([
  customElement("jb-news-page-hero")
], JbNewsPageHero);

export {
  JbNewsPageHero
};
