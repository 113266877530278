import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Filterable
} from "./chunk.TOSSI7AO.js";
import {
  Linkable,
  getLinkIcon
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-listing-item/jb-listing-item.scss?inline
var jb_listing_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
.animate {
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
}
.animate::before {
  animation-direction: reverse;
  background-color: rgba(241, 242, 242, 0.3);
  bottom: 0;
  content: "";
  height: 66%;
  left: 0;
  position: absolute;
  transition: height 0.9s ease-out 0.2s;
  width: 1px;
}
.animate::after {
  background: #fff;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 50%;
  transition: right 0.9s ease-out 0.2s;
}

/* stylelint-disable */
/* stylelint-enable */
.second-line {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

.list-item {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

.second-line {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 0.688rem;
  letter-spacing: 0.063rem;
  line-height: 1rem;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

.list-item {
  color: #141e55;
  display: block;
  font-size: 1rem;
  line-height: 1.625rem;
  position: relative;
}
.list-item::before {
  background: rgba(20, 30, 85, 0.2);
  block-size: 1px;
  content: "";
  inline-size: 100%;
  inset-block-end: 0;
  position: absolute;
}

.animate::before {
  background: rgba(20, 30, 85, var(--theme-opacity-20, 0.2));
  block-size: 1px;
  content: "";
  inline-size: 100%;
  inset-block-end: 0;
  position: absolute;
}
.animate::after {
  background: #141e55;
  inset-inline-end: 100%;
  transition-delay: 0s;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
.animate:hover::before {
  background: rgba(20, 30, 85, 0.2);
  block-size: 1px;
  content: "";
  inline-size: 100%;
  inset-block-end: 0;
  position: absolute;
}
.animate:hover::after {
  background: #141e55;
  inset-inline: 0;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

a {
  color: inherit;
  display: grid;
  gap: 0 40px;
  grid-template-columns: auto min-content;
  padding-block-end: 10px;
  padding-inline-end: 4px;
  text-decoration: none;
}

.icon {
  display: flex;
  padding-block-start: 4px;
}

.filetype {
  text-transform: uppercase;
}`;

// src/components/jb-listing-item/jb-listing-item.ts
var JbListingItem = class extends Filterable(Linkable(r2)) {
  updated(changedProperties) {
    super.updated(changedProperties);
    if (!changedProperties.has("link") && !changedProperties.has("type")) {
      return;
    }
    const target = this.link?.target || this.link?.data?.type === "external" ? "_blank" : void 0;
    const download = !this.link?.target && this.link?.data?.type === "download";
    if (!this.link || target === this.link.target && download === this.link.download) {
      return;
    }
    this.link = { ...this.link, download, target };
  }
  render() {
    return ke`
      <div class=${Rt({ "list-item": true, animate: true })}>
        <a data-linkable>
          <span class="text">
            ${this.text} ${this.file && ke`| <span class="filetype">${this.file.type}</span>, ${this.file.size}`}
          </span>
          <div class="icon">${getLinkIcon(this.link?.data?.type)}</div>
          ${this.date && ke`<span class="second-line">${this.date}</span>`}
        </a>
      </div>
    `;
  }
};
JbListingItem.styles = r(jb_listing_item_default);
__decorateClass([
  n()
], JbListingItem.prototype, "date", 2);
__decorateClass([
  n({ type: Object })
], JbListingItem.prototype, "file", 2);
__decorateClass([
  n()
], JbListingItem.prototype, "text", 2);
JbListingItem = __decorateClass([
  customElement("jb-listing-item")
], JbListingItem);

export {
  JbListingItem
};
