import {
  Linkable
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-language-item/jb-language-item.scss?inline
var jb_language_item_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
a {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

a {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 0.688rem;
  letter-spacing: 0.063rem;
  line-height: 1rem;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: inline-block;
}

li {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  list-style-type: none;
  font-size: 0;
  font-size: 0;
}

a {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  opacity: var(--text-opacity, 0.4);
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.2s ease-in-out;
}
a:hover {
  opacity: 1;
}`;

// src/components/jb-language-item/jb-language-item.ts
var JbLanguageItem = class extends Linkable(r2) {
  render() {
    return ke`<li><a data-linkable>${this.text}</a></li>`;
  }
};
JbLanguageItem.styles = r(jb_language_item_default);
__decorateClass([
  n()
], JbLanguageItem.prototype, "text", 2);
JbLanguageItem = __decorateClass([
  customElement("jb-language-item")
], JbLanguageItem);

export {
  JbLanguageItem
};
