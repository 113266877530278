import { addFormDataEventListener } from '../modules/contactForm';
import { getExternalReferrer, getUtmParameters } from '../modules/referrerData';

function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

function collectReferrerData() {
  let referrerData = JSON.parse(localStorage.getItem('bjb-fe-referrerData') || '{}') as object;
  const referrer = getExternalReferrer();
  if (referrer) {
    referrerData = { ...referrerData, referrer };
  }
  const utmParameters = getUtmParameters();
  if (Object.keys(utmParameters).length > 0) {
    referrerData = { ...referrerData, utmParameters };
  }
  if (isEmpty(referrerData)) {
    return;
  }

  localStorage.setItem('bjb-fe-referrerData', JSON.stringify(referrerData));
}

window.addEventListener('DOMContentLoaded', () => {
  collectReferrerData();
});

addFormDataEventListener((evt: FormDataEvent) => {
  const referrerData = localStorage.getItem('bjb-fe-referrerData');
  if (evt.formData.has('referrerData') || !referrerData) {
    return;
  }
  evt.formData.append('referrerData', referrerData);
});
