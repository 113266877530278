import { LazyLoadElement } from '../features/lazyLoadElement';

import '@bjb/bjb-web-components/dist/components/jb-alert/jb-alert.js';
import '@bjb/bjb-web-components/dist/components/jb-article-back-to-top/jb-article-back-to-top.js';
import '@bjb/bjb-web-components/dist/components/jb-article-row/jb-article-row.js';
import '@bjb/bjb-web-components/dist/components/jb-article-wide/jb-article-wide.js';
import '@bjb/bjb-web-components/dist/components/jb-breadcrumbs/jb-breadcrumbs.js';
import '@bjb/bjb-web-components/dist/components/jb-campaign-teaser/jb-campaign-teaser.js';
import '@bjb/bjb-web-components/dist/components/jb-contact-grid/jb-contact-grid.js';
import '@bjb/bjb-web-components/dist/components/icons/jb-contact-icon/jb-contact-icon.js';
import '@bjb/bjb-web-components/dist/components/icons/jb-e-service-icon/jb-e-service-icon.js';
import '@bjb/bjb-web-components/dist/components/icons/jb-legal-icon/jb-legal-icon.js';
import '@bjb/bjb-web-components/dist/components/jb-contact-overlay/jb-contact-overlay.js';
import '@bjb/bjb-web-components/dist/components/jb-contact-teaser-legacy/jb-contact-teaser-legacy.js';
import '@bjb/bjb-web-components/dist/components/jb-contact-teaser/jb-contact-teaser.js';
import '@bjb/bjb-web-components/dist/components/jb-cookie-consent/jb-cookie-consent.js';
import '@bjb/bjb-web-components/dist/components/jb-cta-small/jb-cta-small.js';
import '@bjb/bjb-web-components/dist/components/jb-headline-with-text/jb-headline-with-text.js';
import '@bjb/bjb-web-components/dist/components/jb-highlight-text-item/jb-highlight-text-item.js';
import '@bjb/bjb-web-components/dist/components/jb-image-page-teaser-item/jb-image-page-teaser-item.js';
import '@bjb/bjb-web-components/dist/components/jb-image/jb-image.js';
import '@bjb/bjb-web-components/dist/components/jb-info-block/jb-info-block.js';
import '@bjb/bjb-web-components/dist/components/jb-info-box/jb-info-box.js';
import '@bjb/bjb-web-components/dist/components/jb-info-page-hero/jb-info-page-hero.js';
import '@bjb/bjb-web-components/dist/components/jb-language-alert/jb-language-alert.js';
import '@bjb/bjb-web-components/dist/components/jb-listing-grid/jb-listing-grid.js';
import '@bjb/bjb-web-components/dist/components/jb-listing/jb-listing.js';
import '@bjb/bjb-web-components/dist/components/jb-logo/jb-logo.js';
import '@bjb/bjb-web-components/dist/components/jb-moment-teaser-item/jb-moment-teaser-item.js';
import '@bjb/bjb-web-components/dist/components/jb-navigation/jb-navigation.js';
import '@bjb/bjb-web-components/dist/components/jb-news-overlay/jb-news-overlay.js';
import '@bjb/bjb-web-components/dist/components/jb-news-page-hero/jb-news-page-hero.js';
import '@bjb/bjb-web-components/dist/components/jb-quote/jb-quote.js';
import '@bjb/bjb-web-components/dist/components/jb-region-indicator/jb-region-indicator.js';
import '@bjb/bjb-web-components/dist/components/jb-richtext/jb-richtext.js';
import '@bjb/bjb-web-components/dist/components/jb-section-sitemap/jb-section-sitemap.js';
import '@bjb/bjb-web-components/dist/components/jb-service-navigation/jb-service-navigation.js';
import '@bjb/bjb-web-components/dist/components/jb-simple-page-hero/jb-simple-page-hero.js';
import '@bjb/bjb-web-components/dist/components/jb-text-teaser/jb-text-teaser.js';

import '../assets/scss/main.scss';
import '../assets/scss/print.scss';

import '../features/addLocationTrackingData.ts';
import '../features/adobeTrackingSettings.ts';
import '../features/alerts.ts';
import '../features/disclaimer.ts';
import '../features/handleTrackingEvents.ts';
import '../features/loadTrackingOnConsent.ts';
import '../features/saveReferrerData.ts';
import '../features/submitPrivacyPreferences.ts';
import '../features/trackConsent.ts';

import '../legacy/main';

new LazyLoadElement(
  'jb-footer',
  () => import('@bjb/bjb-web-components/dist/components/jb-footer/jb-footer.js')
);
new LazyLoadElement(
  'jb-location-teaser',
  () =>
    import(
      '@bjb/bjb-web-components/dist/components/jb-location-teaser/jb-location-teaser.js'
    )
);
