import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Linkable
} from "./chunk.42LHJR2F.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-section-sitemap/jb-section-sitemap.scss?inline
var jb_section_sitemap_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
li jb-anchor::part(anchor) {
  font-family: var(--theme-font-black-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-black-weight, 900);
}

li jb-anchor[inactive]::part(anchor) {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

h2 jb-anchor::part(anchor) {
  font-family: var(--theme-font-light-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-light-weight, 300);
}

li jb-anchor::part(anchor) {
  font-size: var(--theme-typo-uppercase-900-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-900-letterspacing, 0.125rem);
  line-height: var(--theme-typo-uppercase-900-lineheight, 1rem);
  text-transform: uppercase;
}

li jb-anchor[inactive]::part(anchor) {
  font-size: var(--theme-typo-uppercase-700-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-700-letterspacing, 0.063rem);
  line-height: var(--theme-typo-uppercase-700-lineheight, 1rem);
  text-transform: uppercase;
}

h2 jb-anchor::part(anchor) {
  font-size: var(--theme-typo-h2-section-title-mobile-fontsize, 1.5rem);
  letter-spacing: var(--theme-typo-h2-section-title-mobile-letterspacing, 0.125rem);
  line-height: var(--theme-typo-h2-section-title-mobile-lineheight, 2.25rem);
}
@media (min-width: 1281px) {
  h2 jb-anchor::part(anchor) {
    font-size: var(--theme-typo-h2-section-title-desktop-fontsize, 1.938rem);
    letter-spacing: var(--theme-typo-h2-section-title-desktop-letterspacing, 0.25rem);
    line-height: var(--theme-typo-h2-section-title-desktop-lineheight, 2.5rem);
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
nav {
  --_grid-gap: 16px;
  --_grid-template-columns: repeat(12, 1fr);
  display: grid;
  gap: var(--grid-row-gap, 0) var(--grid-column-gap, var(--_grid-gap));
  grid-template-columns: var(--theme-grid-template-columns, var(--_grid-template-columns));
  width: 100%;
}
@media (min-width: 960px) {
  nav {
    --_grid-gap: 40px;
  }
}

section {
  --_max-width: calc(100% - (2 * 6.68%));
  display: grid;
  grid-template-columns: [full-start] minmax(0, 1fr) [main-start] minmax(0, var(--theme-grid-max-width, var(--_max-width))) [main-end] minmax(0, 1fr) [full-end];
  margin-inline: auto;
}
@media (min-width: 600px) {
  section {
    --_max-width: calc(100% - (2 * 8.35%));
  }
}
@media (min-width: 960px) {
  section {
    --_max-width: calc(100% - (2 * 6.955%));
  }
}
@media (min-width: 1437px) {
  section {
    --_max-width: 1437px;
  }
}

:host {
  color: var(--host-color, #141e55);
}

section {
  background-color: rgba(113, 124, 125, 0.1);
  padding: var(--spacing-xl) 0;
  padding-inline-end: var(--compensate-scrollbar);
}
@media (min-width: 600px) {
  section {
    padding: 48px 0;
  }
}
@media (min-width: 960px) {
  section {
    padding: 64px 0;
  }
}
@media (min-width: 1281px) {
  section {
    padding: var(--spacing-2xl) 0;
  }
}

nav {
  grid-column: main;
}

h2 {
  grid-column: 1/13;
  margin-block-end: var(--spacing-sm);
  text-align: center;
  text-transform: uppercase;
  transition: opacity 0.3s ease-in-out 0s;
}
@media (min-width: 600px) {
  h2 {
    grid-column: 1/7;
    text-align: start;
  }
}
@media (min-width: 960px) {
  h2 {
    grid-column: 2/6;
  }
}
@media (min-width: 1281px) {
  h2 {
    margin-block-end: 0;
  }
}
h2:hover {
  opacity: 0.6;
}
h2 jb-anchor::part(anchor) {
  color: #141e55;
  text-decoration: none;
}

ul {
  grid-column: 1/13;
  list-style-type: none;
}
@media (min-width: 600px) {
  ul {
    grid-column: 7/13;
  }
}
@media (min-width: 960px) {
  ul {
    column-count: 2;
    column-gap: var(--_grid-gap, 40px);
    grid-column: 6/12;
  }
}

li {
  margin-block-end: var(--spacing-sm);
  text-align: center;
}
@media (min-width: 600px) {
  li {
    text-align: start;
  }
}
li jb-anchor::part(anchor) {
  text-decoration-color: color-mix(in srgb, currentcolor, transparent 100%);
  text-decoration-thickness: 7.5%;
  text-transform: uppercase;
  text-underline-offset: 40%;
  text-underline-position: from-font;
  transition: ease-in-out 0.2s text-decoration-color;
}
li jb-anchor::part(anchor):hover {
  text-decoration-color: color-mix(in srgb, currentcolor, transparent 30%);
}
@supports not (text-decoration-color: color-mix(in srgb, currentcolor, transparent 100%)) {
  li jb-anchor::part(anchor) {
    text-decoration-color: transparent;
  }
  li jb-anchor::part(anchor):hover {
    text-decoration-color: currentcolor;
  }
}
li jb-anchor[inactive]::part(anchor) {
  opacity: 0.8;
}

@media (min-width: 600px) {
  .intranet h2 {
    text-align: left;
  }
}
@media (min-width: 960px) {
  .intranet h2 {
    grid-column: 1/6;
  }
}`;

// src/components/jb-section-sitemap/jb-section-sitemap.ts
var JbSectionSitemap = class extends Linkable(r2) {
  _renderItems(items) {
    return items.map((item) => ke`<li><jb-anchor .link=${item.link}>${item.text}</jb-anchor></li>`);
  }
  render() {
    return ke`
      <section class="${Rt({ intranet: this.intranetStyle })}">
        <nav>
          <h2><jb-anchor .link=${this.link}>${this.text}</jb-anchor></h2>
          <ul>
            ${this._renderItems(this.items)}
          </ul>
        </nav>
      </section>
    `;
  }
};
JbSectionSitemap.styles = r(jb_section_sitemap_default);
__decorateClass([
  n({ type: Boolean })
], JbSectionSitemap.prototype, "intranetStyle", 2);
__decorateClass([
  n({ type: Array })
], JbSectionSitemap.prototype, "items", 2);
__decorateClass([
  n()
], JbSectionSitemap.prototype, "text", 2);
JbSectionSitemap = __decorateClass([
  customElement("jb-section-sitemap")
], JbSectionSitemap);

export {
  JbSectionSitemap
};
