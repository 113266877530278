import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  D,
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-headline-with-text/jb-headline-with-text.scss?inline
var jb_headline_with_text_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
h5 {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

h4 {
  font-family: var(--theme-font-xlight-family, VerlagSSm, Tahoma, sans-serif);
  font-style: normal;
  font-weight: var(--theme-font-xlight-weight, 200);
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

h4 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem;
  color: #141e55;
  font-weight: 300;
  padding-bottom: 24px;
}
@media (min-width: 960px) {
  h4 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 36px;
    line-height: 2.25rem;
  }
}
h4.with-border {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 36px;
  line-height: 2.25rem;
  border-bottom: 1px solid rgba(20, 30, 85, 0.2);
  font-weight: 100;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

h5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 26px;
  line-height: 1.625rem;
  color: rgba(20, 30, 85, 0.8);
  margin-bottom: 10px;
}

::slotted(jb-richtext) {
  --host-color: rgba(20, 30, 85, var(--theme-opacity-80, 0.8));
  --text-opacity: 0.8;
}`;

// src/components/jb-headline-with-text/jb-headline-with-text.ts
var JbHeadlineWithText = class extends r2 {
  render() {
    return ke`
      ${this.headline ? ke`<h4 class="${this.withBorder ? "with-border" : ""}">${this.headline}</h4>` : D}
      ${this.subtitle ? ke`<h5>${this.subtitle}</h5>` : D}
      <slot></slot>
    `;
  }
};
JbHeadlineWithText.styles = r(jb_headline_with_text_default);
__decorateClass([
  n()
], JbHeadlineWithText.prototype, "headline", 2);
__decorateClass([
  n()
], JbHeadlineWithText.prototype, "subtitle", 2);
__decorateClass([
  n({ type: Boolean })
], JbHeadlineWithText.prototype, "withBorder", 2);
JbHeadlineWithText = __decorateClass([
  customElement("jb-headline-with-text")
], JbHeadlineWithText);

export {
  JbHeadlineWithText
};
