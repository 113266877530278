import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-listing-filter/jb-listing-filter.scss?inline
var jb_listing_filter_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
li {
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
}
li::before {
  animation-direction: reverse;
  background-color: rgba(241, 242, 242, 0.3);
  bottom: 0;
  content: "";
  height: 66%;
  left: 0;
  position: absolute;
  transition: height 0.9s ease-out 0.2s;
  width: 1px;
}
li::after {
  background: #fff;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 50%;
  transition: right 0.9s ease-out 0.2s;
}

/* stylelint-disable */
/* stylelint-enable */
a {
  font-family: var(--theme-font-bold-family, VerlagSSm, Tahoma, sans-serif);
  font-weight: var(--theme-font-bold-weight, 700);
}

a {
  font-size: var(--theme-typo-uppercase-700-fontsize, 0.688rem);
  letter-spacing: var(--theme-typo-uppercase-700-letterspacing, 0.063rem);
  line-height: var(--theme-typo-uppercase-700-lineheight, 1rem);
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: flex;
  justify-content: center;
}

ul {
  display: flex;
  gap: var(--spacing-xs);
  list-style-type: none;
  overflow-x: auto;
  white-space: nowrap;
}

li {
  display: inline-flex;
  min-height: 0;
  opacity: 0.4;
  overflow: visible;
  padding-block: var(--spacing-base);
  transition: opacity 0.4s ease-in-out 0s;
  white-space: nowrap;
}
li::before {
  background-color: transparent;
  border-bottom: 1px solid rgb(var(--theme-color-royal-blue, 20 30 85)/var(--theme-opacity-20, 0.2));
  height: 1px;
  width: 100%;
}
li::after {
  background: rgb(var(--theme-color-blue, 0 20 137));
  right: 100%;
}
li:hover, li.active {
  opacity: 1;
}
li:hover::after, li.active::after {
  right: 0 !important;
  transition-duration: 0.5s !important;
}

a {
  color: rgb(var(--theme-color-royal-blue, 20 30 85));
  text-decoration: none;
}

.intranet {
  gap: var(--spacing-3xs);
}
.intranet li {
  padding-block: var(--spacing-base);
  padding-inline: var(--spacing-3xs);
  transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
}
.intranet li a {
  color: inherit;
}
.intranet li:hover, .intranet li.active {
  background-color: rgb(var(--theme-color-royal-blue, 20 30 85));
  color: rgb(var(--theme-color-white, 255 255 255));
}
.intranet li::before {
  all: unset;
}
.intranet li::after {
  all: unset;
}`;

// src/components/jb-listing-filter/jb-listing-filter.ts
var JbListingFilter = class extends r2 {
  constructor() {
    super(...arguments);
    this.intranetStyle = false;
  }
  _handleFilterClick(event) {
    event.preventDefault();
    const newValue = event.target.dataset.filterValue;
    if (this.activeValue === newValue) {
      return;
    }
    this.activeValue = newValue;
    this.dispatchEvent(
      new CustomEvent("jb-listing-filter:change", {
        detail: {
          filterAspect: this.filterAspect,
          newValue
        },
        bubbles: true,
        composed: true
      })
    );
    this.requestUpdate();
  }
  render() {
    return ke`
      <ul class="${Rt({ intranet: this.intranetStyle })}">
        ${this.options.map(
      (option) => ke`<li class="${Rt({ active: option.value === this.activeValue })}">
              <a
                @click=${this._handleFilterClick}
                data-filter-value=${option.value}
                href="#"
                >${option.label}</a
              >
            </li>`
    )}
      </ul>
    `;
  }
};
JbListingFilter.styles = r(jb_listing_filter_default);
__decorateClass([
  n()
], JbListingFilter.prototype, "activeValue", 2);
__decorateClass([
  n()
], JbListingFilter.prototype, "filterAspect", 2);
__decorateClass([
  n({ type: Boolean })
], JbListingFilter.prototype, "intranetStyle", 2);
__decorateClass([
  n({ type: Array })
], JbListingFilter.prototype, "options", 2);
JbListingFilter = __decorateClass([
  customElement("jb-listing-filter")
], JbListingFilter);

export {
  JbListingFilter
};
