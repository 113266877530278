import {
  Rt
} from "./chunk.LQ2ZH6PS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  Stackable
} from "./chunk.YYW5LEED.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  e,
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-popup/jb-popup.scss?inline
var jb_popup_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  box-shadow: 0px 0px 0px 3px rgb(30 40 85/0.7);
  border-radius: 3px;
  outline: 2px solid rgb(255 255 255/0.7);
  outline-offset: 8px;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

section {
  background-color: rgba(0, 0, 0, 0.4);
  inset: 0;
  overflow-y: auto;
  padding: 30px 0;
  position: fixed;
}
section::-webkit-scrollbar {
  background-color: #141e55;
  width: 5px;
}
section::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #f1f2f2;
}
section::-webkit-scrollbar-thumb {
  background-color: #141e55;
  border-radius: 4px;
  width: 180px;
}
@media (min-width: 1281px) {
  section {
    align-items: center;
    display: flex;
    padding: 0;
  }
}
section.animates {
  opacity: 0;
  transition: opacity 400ms ease-in;
}
section.animates .slot-container-inner {
  transform: scale(0.9);
  transition: transform 400ms ease-in;
}
section.animates.visible {
  opacity: 1;
  transition-timing-function: ease-out;
}
section.animates.visible .slot-container-inner {
  transform: scale(1);
  transition-timing-function: ease-out;
}

.container {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * var(--row-small-margin, 6.68%));
  justify-content: center;
  position: relative;
}
@media (min-width: 600px) and (max-width: 959px) {
  .container {
    width: calc(100% - 2 * 8.35%);
  }
}
@media (min-width: 1437px) {
  .container {
    max-width: var(--row-max-width, 1436px);
  }
  .container:not(.filtered-list) {
    width: 100%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1436px) {
  .container {
    margin: 0 6.955%;
    max-width: calc(100% - 6.955% - 6.955%);
  }
}

.slot-container-col {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 20px;
  width: 100%;
  padding: 0;
}
@media (min-width: 1281px) {
  .slot-container-col {
    box-sizing: border-box;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
    padding: 0 20px;
    width: 83.3333333333%;
  }
}

.slot-container-outer {
  transition: height 800ms ease;
}

.slot-container-inner {
  opacity: 1;
  transition: opacity 400ms ease;
}

.transition-helper-container {
  box-sizing: border-box;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  padding: 0 0;
  width: 83.3333333333%;
  opacity: 0;
  position: absolute;
  top: 0;
}`;

// src/components/jb-popup/jb-popup.ts
var JbPopup = class extends Initiable(Stackable(r2)) {
  constructor() {
    super(...arguments);
    this._internalStack = [];
  }
  firstUpdated() {
    if (this.animates && this._introPromiseResolve) {
      window.setTimeout(() => {
        this._background.classList.add("visible");
        this._background.addEventListener("transitionend", this._introPromiseResolve, { once: true });
      }, 10);
    }
    window.setTimeout(() => {
      this._slotContainerOuter.style.height = `${this._slotContainerOuter.getBoundingClientRect().height}px`;
    }, 0);
  }
  async intro() {
    if (!this.animates) {
      return void 0;
    }
    return new Promise((resolve) => {
      this._introPromiseResolve = resolve;
    });
  }
  async outro() {
    if (!this.animates) {
      return void 0;
    }
    return new Promise((resolve) => {
      this._background.classList.remove("visible");
      this._background.addEventListener("transitionend", resolve, { once: true });
    });
  }
  transitionBack() {
    const previousChild = this._internalStack.pop();
    if (!previousChild) {
      this.unstack();
      return;
    }
    const currentChildElement = this._slot?.assignedElements()[0];
    if (!currentChildElement) {
      return;
    }
    this._transitionChildFromTo(currentChildElement, previousChild);
  }
  transitionTo(nextChildElement) {
    const currentChildElement = this._slot?.assignedElements()[0];
    if (!currentChildElement) {
      return;
    }
    this._internalStack.push(currentChildElement);
    this._transitionChildFromTo(currentChildElement, nextChildElement).then(() => {
    });
  }
  unstacked() {
    this.remove();
  }
  _handlePopupBackgroundClick(event) {
    if (this.preventCloseByBackgroundClick) {
      return;
    }
    if (event.target === this._background || event.target === this._container) {
      this.unstack();
    }
  }
  _handleTransitionEvent(event) {
    this.transitionTo(event.detail.nextChild);
  }
  async _transitionChildFromTo(fromElement, toElement) {
    fromElement.style.setProperty("--background-color", "transparent");
    toElement.style.setProperty("--background-color", "transparent");
    this._slotContainerOuter.style.backgroundColor = "rgb(20, 30, 85)";
    toElement.setAttribute("slot", "transition-helper");
    await this.appendChild(toElement);
    window.setTimeout(() => {
      this._slotContainerInner.addEventListener(
        "transitionend",
        () => {
          this._slotContainerInner.addEventListener(
            "transitionend",
            () => {
              window.setTimeout(() => {
                fromElement.style.setProperty("--background-color", "unset");
                toElement.style.setProperty("--background-color", "unset");
                this._slotContainerOuter.style.backgroundColor = "transparent";
              }, 500);
            },
            { once: true }
          );
          this._slotContainerInner.style.opacity = "1";
          this.removeChild(fromElement);
          toElement.removeAttribute("slot");
          this._slotContainerOuter.style.height = `${toElement.getBoundingClientRect().height}px`;
        },
        { once: true }
      );
      this._slotContainerOuter.style.height = `${toElement.getBoundingClientRect().height}px`;
      this._slotContainerInner.style.opacity = "0";
    }, 0);
  }
  render() {
    return ke`
      <section
        @click=${this._handlePopupBackgroundClick}
        class="${Rt({ animates: !!this.animates })}"
        style=${`z-index: ${this.zIndex()}`}
      >
        <div class="container">
          <div class="slot-container-col">
            <div class="slot-container-outer">
              <div class="slot-container-inner">
                <slot
                  @jb-popup:close=${this.transitionBack}
                  @jb-popup:close-stack=${this.unstack}
                  @jb-popup:transition=${this._handleTransitionEvent}
                ></slot>
              </div>
            </div>
          </div>
          <div class="transition-helper-container">
            <slot name="transition-helper"></slot>
          </div>
        </div>
      </section>
    `;
  }
};
JbPopup.styles = r(jb_popup_default);
__decorateClass([
  n({ type: Boolean })
], JbPopup.prototype, "animates", 2);
__decorateClass([
  n({ type: Boolean })
], JbPopup.prototype, "preventCloseByBackgroundClick", 2);
__decorateClass([
  e("section")
], JbPopup.prototype, "_background", 2);
__decorateClass([
  e(".container")
], JbPopup.prototype, "_container", 2);
__decorateClass([
  e("slot")
], JbPopup.prototype, "_slot", 2);
__decorateClass([
  e(".slot-container-inner")
], JbPopup.prototype, "_slotContainerInner", 2);
__decorateClass([
  e(".slot-container-outer")
], JbPopup.prototype, "_slotContainerOuter", 2);
__decorateClass([
  e('slot[name="transition-helper"]')
], JbPopup.prototype, "_transitionHelperSlot", 2);
JbPopup = __decorateClass([
  customElement("jb-popup")
], JbPopup);

export {
  JbPopup
};
